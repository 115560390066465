<template>
    <p>Edit</p>
</template>

<script>
export default {
  props: ['task']
}
</script>

<style scoped>
.task-details{
    border:1px solid black;
    width:40%;
    margin:15px auto;
    padding:10px;
}
</style>
