import { createRouter, createWebHistory } from 'vue-router'
import TasksList from '../views/TasksList.vue'
import TaskDetails from '../views/task/TaskDetails.vue'
import TaskLayout from '../views/task/Layout.vue'
import TaskEdit from '../views/task/TaskEdit.vue'
import TaskDelete from '../views/task/TaskDelete.vue'
import NotFoundComponent from '../views/NotFoundComponent.vue'

const routes = [
  {
    path: '/',
    name: 'TasksList',
    component: TasksList,
    props: route => ({ page: parseInt(route.query.page) || 1 })
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/tasks/:id',
    name: 'TaskLayout',
    props: true,
    component: TaskLayout,
    children: [
      {
        path: '',
        name: 'TaskDetails',
        component: TaskDetails
      },
      {
        path: '',
        name: 'TaskEdit',
        component: TaskEdit
      },
      {
        path: '',
        name: 'TaskDelete',
        component: TaskDelete
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    component: NotFoundComponent
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
